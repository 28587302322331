














































































































import Vue from 'vue';

export default Vue.extend({
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
